import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import Head from "../components/head"
import ContactForm from "../components/contactForm"

import { SmoothVerticalScrolling } from "../utils/scroll"

import call_us from "../images/icons/callus-icon.svg"
import write_us from "../images/icons/writeus-icon.svg"
import visit_us from "../images/icons/visitus-icon.svg"
import see_map from "../images/icons/seemap-icon.svg"
import arrow from "../images/icons/arrow-down.svg"

const Introduction = styled.div`
  ${tw`relative bg-dark flex w-full`};
  margin: 0 auto;
`
const IntroductionContent = styled.div`
  ${tw` px-5 relative lg:items-center pt-4 sm:pt-6 md:pt-12 lg:pt-20 pb-7 sm:pb-10 md:pb-16 lg:pb-32  max-w-default bg-dark flex flex-col lg:flex-row w-full`};
  margin: 10px auto 14px;
`

const Title = styled.h1`
  ${tw`text-40 sm:text-5xl md:text-xxl leading-snug w-2/5 font-semibold text-white`}
`

const DescriptionWrapper = styled.div`
  ${tw`w-full lg:w-3/5 pt-8 lg:pt-0`}
`
const Description = styled.p`
  ${tw`text-base md:text-lg text-white font-light leading-testimonial`}
`

const ContactSection = styled.div`
  ${tw`w-full bg-light relative`}
`

const Container = styled.div`
  ${tw`px-5 relative items-start pt-16 lg:pt-24 pb-4 sm:pb-16 lg:pb-24  max-w-default flex w-full flex-col-reverse md:flex-row`};
  margin: 0 auto;
`
const ContactInformation = styled.div`
  ${tw`flex flex-col w-full text-center md:text-left pt-8 md:pt-20 md:w-2/5`}
`
const ContactInformationText = styled.h1`
  ${tw`text-dark font-semibold text-base md:text-lg`}
`
const ContactInformationMail = styled.h1`
  ${tw`text-dark font-semibold text-base md:text-lg`}
`

const Icon = styled.img`
  margin-top: 3px;
  @media only screen and (max-width: 767px) {
    margin-top: 1px;
  }
`

const ContactInformationMap = styled.div`
  ${tw``}
`
const ContactSectionWrapper = styled.div`
  ${tw`flex items-start `}
`
const ContactSectionMailWrapper = styled.div`
  ${tw`flex items-start pb-8 md:pb-10 pt-8 md:pt-10`}
`
const ContactSectionPlaceWrapper = styled.div`
  ${tw`flex items-start pb-8 `}
`

const ContactSectionMapWrapper = styled.div`
  ${tw`flex items-start pb-2 `}
`
const ContactInformationLabel = styled.h1`
  ${tw`pr-5 md:pr-8`}
`
const ContactInformationMapSpan = styled.span`
  ${tw`text-dark underline cursor-pointer font-semibold text-base md:text-lg`}
`
const TextLeft = styled.div`
  ${tw`text-left`}
`
const ScrollToTop = styled.div`
  ${tw`absolute cursor-pointer flex md:hidden items-center bottom-0 right-0 mr-5 opacity-40 mb-8`}
`
const ScrollToTopText = styled.p`
  ${tw`text-dark font-semibold pr-3`}
`
const IconArrow = styled.img`
  ${tw``}
  transform: rotate(180deg)
`

const ContactPage = () => {
  const intl = useIntl()

  const scrollTop = () => {
    SmoothVerticalScrolling(document.getElementById("top-nav"), 400, "top")
  }
  return (
    <Layout>
      <Head lang={intl.locale} title="Contact" />
      <Introduction id="contact-section">
        <IntroductionContent>
          <Title>
            <FormattedMessage id="contact.title" />
          </Title>
          <DescriptionWrapper>
            <Description>
              <FormattedMessage id="contact.description_1" />
              <b>
                <FormattedMessage id="contact.description_bold_1" />
              </b>
              <FormattedMessage id="contact.description_2" />
              <b>
                <FormattedMessage id="contact.description_bold_2" />
              </b>
              <FormattedMessage id="contact.description_3" />
              <b>
                <FormattedMessage id="contact.description_bold_3" />
              </b>
            </Description>
          </DescriptionWrapper>
        </IntroductionContent>
      </Introduction>
      <ContactSection>
        <Container>
          <ContactInformation>
            <ContactSectionWrapper>
              <ContactInformationLabel>
                <Icon src={call_us} />
              </ContactInformationLabel>
              <TextLeft>
                <ContactInformationText>
                  SK +421 911 595 465
                </ContactInformationText>
                <ContactInformationText>
                  CH +41 784 22 24
                </ContactInformationText>
              </TextLeft>
            </ContactSectionWrapper>
            <ContactSectionMailWrapper>
              <ContactInformationLabel>
                <Icon src={write_us} />
              </ContactInformationLabel>

              <ContactInformationMail>
                kristian@neuejob.com
              </ContactInformationMail>
            </ContactSectionMailWrapper>
            <ContactSectionPlaceWrapper>
              <ContactInformationLabel>
                <Icon src={visit_us} />
              </ContactInformationLabel>
              <TextLeft>
                <ContactInformationText>Biznis centrum</ContactInformationText>
                <ContactInformationText>Partizánska 1</ContactInformationText>
                <ContactInformationText>058 01 Poprad</ContactInformationText>
                <ContactInformationText>Slovakia</ContactInformationText>
              </TextLeft>
            </ContactSectionPlaceWrapper>
            <ContactSectionMapWrapper>
              <ContactInformationLabel>
                <Icon src={see_map} />
              </ContactInformationLabel>
              <ContactInformationMap>
                <ContactInformationMapSpan>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.google.com/maps/place/Partiz%C3%A1nska+1,+058+01+Poprad,+Slovakia/@49.0552755,20.2897586,17z/data=!3m1!4b1!4m5!3m4!1s0x473e3abe390f18af:0xb94c2ceec2ee2008!8m2!3d49.0552755!4d20.2919526"
                  >
                    <FormattedMessage id="contact.see_map" />
                  </a>
                </ContactInformationMapSpan>
              </ContactInformationMap>
            </ContactSectionMapWrapper>
          </ContactInformation>
          <ContactForm />
        </Container>
        <Container>
          <ScrollToTop onClick={scrollTop}>
            <ScrollToTopText>
              <FormattedMessage id="faq.scroll_top" />
            </ScrollToTopText>
            <IconArrow src={arrow} alt="arrow" />
          </ScrollToTop>
        </Container>
      </ContactSection>
    </Layout>
  )
}

export default ContactPage
