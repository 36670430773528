import React from "react"
import styled from "styled-components"
import tw from "twin.macro"

import ReactModal from "react-modal"

import {
  useIntl,
  FormattedMessage,
  createIntl,
  createIntlCache,
} from "gatsby-plugin-intl"

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.85)",
  },
  content: {
    top: "50%",
    marginTop: "6vh",
    height: "70vh",
    padding: "0px !important",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "calc(-50% - -39px)",
    maxWidth: "1024px",
    transform: "translate(-50%, -50%)",
    borderRadius: "24px",
    display: "flex",
    flexDirection: "column",
  },
}

const Wrapper = styled.div`
  ${tw`bg-white w-full flex flex-col w-full md:w-3/5 px-4 sm:px-10 lg:px-24 pt-6 sm:pt-12 lg:pt-20 pb-12 lg:pb-20 mb-14 lg:mb-2 relative shadow-values`}
  border-radius: 86px;
  max-width: 680px;
  @media only screen and (max-width: 1023px) {
    border-radius: 48px;
  }
  @media only screen and (max-width: 767px) {
    max-width: 600px;
  }
`
const Form = styled.form`
  ${tw`flex flex-col`}
`

const Button = styled.button`
  ${tw`px-5 sm:px-8 w-full mt-4 sm:mt-6 py-3 sm:py-5 text-dark font-semibold bg-primary rounded-full text-lg focus:outline-none hover:bg-primaryHovered duration-300`}
`

const TermsCondition = styled.p`
  ${tw`text-dark mt-5 sm:mt-8 text-xs`}
`
const TermsLink = styled.a`
  ${tw`text-dark underline font-medium cursor-pointer`}
`
const Thanks = styled.div`
  ${tw`bg-white absolute flex justify-center items-center flex-col duration-300`}
  border-radius: 86px;
  max-width: 680px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media only screen and (max-width: 1023px) {
    border-radius: 48px;
  }
`
const ThanksMessage = styled.h1`
  ${tw`font-bold text-2xl text-dark text-center px-8 -mt-12`}
  letter-spacing: .75px
`
const ThanksText = styled.p`
  ${tw`text-center text-dark font-semibold px-8 pt-3 text-lg`}
`
const Brake = styled.br`
  ${tw`block sm:hidden`}
`

const ModalWrapper = styled.div`
  ${tw`pl-5 md:pl-10 pr-4 md:pr-8 pb-5 mr-1 mt-1 mb-3 rounded`}
  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar-track
    {
    margin: 10px 0px
    border-radius: 10px;
    background-color: #ffffff;
    }
  &::-webkit-scrollbar
    {
    width: 10px;
    background-color: #F5F5F5;
    }
  &::-webkit-scrollbar-thumb
    {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
    }
`
const ModalHeadingWrapper = styled.div`
  ${tw`block`}
`
const ModalHeading = styled.div`
  ${tw`flex justify-between py-5 md:py-7 px-5 md:px-10 border-b-2 border-gray-200`}
`

const TermsModalTitle = styled.h1`
  ${tw`md:text-3xl text-2xl font-bold text-dark leading-normal pr-6`}
`

const TermsModalHeading = styled.h2`
  ${tw`md:text-xl text-2xl font-bold text-dark leading-normal mt-8 `}
`
const TermsModalText = styled.p`
  ${tw`text-base md:text-lg text-dark opacity-50`}
  line-height: 33px;
  @media (hover: none) {
    line-height: 30px;
  }
`

const CloseModal = styled.button`
  ${tw``}
`
const CloseModalWrapper = styled.div`
  ${tw`flex justify-end pt-2 items-start`}
`
const UnorderedList = styled.ul`
  list-style-type: circle;
  padding-left: 18px;
`
const ListItem = styled.li`
  ${tw`text-base md:text-lg text-dark opacity-40 mb-2`}
  line-height: 30px;
  @media (hover: none) {
    line-height: 30px;
  }
`

const cache = createIntlCache()

const intl = createIntl(
  {
    locale: "sk",
    messages: {
      dd_construction: "Stavebníctvo",
      dd_hospitality: "Hotelierstvo",
      dd_it: "IT a počítače",
      dd_others: "Ostatné",
      dd_crane_operator: "Žeriavnik",
      dd_scaffolder: "Lešenár",
      dd_electrician: "Elektrikár",
      dd_water_installer: "Vodoinštalatér",
      dd_welder: "Zvárač",
      dd_mason: "Murár",
      dd_locksmith: "Zámočník",
      dd_bartender: "Barman/ka",
      dd_waiter: "Čašník/čka",
      dd_chef: "Kuchár/ka",
      dd_designer: "Dizajnér/ka",
      dd_developer: "Developer/ka",
      dd_manager: "Manažér/ka",
      dd_nurse: "Zdravotná sestra",
      dd_babysitter: "Opatrovateľka",
      dd_farm_worker: "Farmárske práce",
    },
  },
  cache
)

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
      name: "",
      email: "",
      phone: "",
      country: "",
      type: "",
      interest: "",
      validMail: true,
      speak_german: false,
      work_experience: false,
      open: false,
      formFilled: false,
      interestList: [
        { value: "dd_construction", label: "dd_construction" },
        { value: "dd_hospitality", label: "dd_hospitality" },
        { value: "dd_it", label: "dd_it" },
        { value: "dd_others", label: "dd_others" },
      ],
      typeList: [
        { value: "dd_company", label: "dd_company" },
        { value: "dd_candidate", label: "dd_candidate" },
      ],
      selectedInterest: null,
      selectedInterestParsed: null,
      selectedType: null,
      selectedTypeParsed: null,
      empty: "empty",
    }
  }

  componentDidMount() {
    window.addEventListener("mousedown", this.handleClick, false)
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handleClick, false)
  }

  handleModalOpen = event => {
    this.setState({ isModalOpen: true })
  }

  handleModalClose = event => {
    this.setState({ isModalOpen: false })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    if (name === "email") {
      this.setState({
        validMail: this.validateEmail(value),
      })
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        this.handleFilledForm()
      }
    )
  }

  handleExperienceCheckbox = ()=>{
    this.setState({work_experience: !this.state.work_experience})
  }

  handleLanguageCheckbox = ()=>{
    this.setState({speak_german: !this.state.speak_german})
  }

  handleChange = selectedOption => {
    const key = selectedOption.type
    this.setState(
      {
        [key]: selectedOption.value,
      },
      () => {
        this.handleFilledForm()
      }
    )
  }

  handleSubmit = event => {
    if (this.state.formFilled) {
      const formObj = {
        "Meno a Priezvisko": this.state.name,
        "Emailová adresa": this.state.email,
        "Telefónne číslo": this.state.phone,
        "skúsenosti s touto prácou": this.state.work_experience ?'Áno' :'Nie',
        "Rozprávam po nemecky": this.state.speak_german ?'Áno' :'Nie',
        Štát: this.state.country,
        "Zaujímam sa o": this.state.selectedInterestParsed,
        "Pracovná pozícia": this.state.selectedTypeParsed,
      }
      event.preventDefault()
      console.log(formObj)
      fetch("https://submit-form.com/3biW1s5w", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formObj),
      })
        .then(this.handleSuccess)
        .catch(function(error) {
          console.error(error)
        })
    } else {
      event.preventDefault()
    }
  }

  handleSuccess = () => {
    this.setState({
      name: "",
      email: "",
      phone: "",
      country: "",
      type: "",
      interest: "",
      open: true,
      speak_german: false,
      work_experience: false,
      validMail: true,
      formFilled: false,
      selectedInterest: null,
      selectedInterestParsed: null,
      selectedType: null,
      selectedTypeParsed: null,
    })
  }

  validateEmail = email => {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexp.test(email)
  }

  filterDropdownClick = filterType => {
    switch (filterType) {
      case "interest-dd":
        if (this.state.activeFilterDropdown === "interest-dd") {
          this.setState({ activeFilterDropdown: null })
        } else {
          this.setState({ activeFilterDropdown: "interest-dd" })
        }
        break
      case "type-dd":
        if (this.state.activeFilterDropdown === "type-dd") {
          this.setState({ activeFilterDropdown: null })
        } else {
          this.setState({ activeFilterDropdown: "type-dd" })
        }
        break
      default:
        break
    }
  }

  selectDDValue = (obj, filterType) => {
    let { selectedInterest, selectedType } = this.state
    switch (filterType) {
      case "interest-dd":
        selectedInterest = obj
        this.setState(
          {
            selectedInterest,
            interest: selectedInterest,
            activeFilterDropdown: null,
            selectedInterestParsed: intl.formatMessage({
              id: obj.label,
            }),
          },
          () => {
            this.handleFilledForm()
          }
        )
        break
      case "type-dd":
        selectedType = obj
        this.setState(
          {
            selectedType,
            type: selectedType,
            activeFilterDropdown: null,
            selectedTypeParsed: intl.formatMessage({
              id: obj.label,
            }),
          },
          () => {
            this.handleFilledForm()
          }
        )
        break
      default:
        break
    }
  }
  handleFilledForm = () => {
    if (
      this.state.name !== "" &&
      this.state.validMail &&
      this.state.phone !== "" &&
      this.state.interest !== "" &&
      this.state.type !== "" &&
      this.state.country !== ""
    ) {
      this.setState({ formFilled: true })
    } else {
      this.setState({ formFilled: false })
    }
  }

  handleClick = e => {
    if (
      (this.interestDD && this.interestDD.contains(e.target)) ||
      (this.typeDD && this.typeDD.contains(e.target))
    ) {
      return
    }
    this.handleClickOutside()
  }

  handleClickOutside = () => {
    this.setState({ activeFilterDropdown: null })
  }

  render() {
    const field = this.state.interest.label
    let specialization
    if (field === "dd_construction") {
      specialization = [
        { value: "dd_crane_operator", label: "dd_crane_operator" },
        { value: "dd_scaffolder", label: "dd_scaffolder" },
        { value: "dd_electrician", label: "dd_electrician" },
        { value: "dd_water_installer", label: "dd_water_installer" },
        { value: "dd_welder", label: "dd_welder" },
        { value: "dd_mason", label: "dd_mason" },
        { value: "dd_locksmith", label: "dd_locksmith" },
      ]
    } else if (field === "dd_hospitality") {
      specialization = [
        { value: "dd_bartender", label: "dd_bartender" },
        { value: "dd_waiter", label: "dd_waiter" },
        { value: "dd_chef", label: "dd_chef" },
      ]
    } else if (field === "dd_it") {
      specialization = [
        { value: "dd_designer", label: "dd_designer" },
        { value: "dd_developer", label: "dd_developer" },
        { value: "dd_manager", label: "dd_manager" },
      ]
    } else if (field === "dd_others") {
      specialization = [
        { value: "dd_nurse", label: "dd_nurse" },
        { value: "dd_babysitter", label: "dd_babysitter" },
        { value: "dd_farm_worker", label: "dd_farm_worker" },
      ]
    }   
    else {
      specialization = [
        { value: "dd_bartender", label: "dd_bartender" },
      ]
    }
    
    return (
      <>
        <Wrapper id="contact">
          <Form onSubmit={this.handleSubmit} target="_self" name="contact">
            <input
              type="checkbox"
              name="_honeypot"
              tabIndex="-1"
              autoComplete="off"
              hidden
            />
            <div className="form-field">
              <div className="form-field__control">
                <input
                  id="formName"
                  type="text"
                  name="name"
                  className="form-field__input"
                  onChange={this.handleInputChange}
                  value={this.state.name}
                  required
                  placeholder=" "
                />
                <label htmlFor="formName" className="form-field__label">
                  <FormattedMessage id="placeholder.name" />
                </label>
                <div className="form-field__bar"></div>
              </div>
            </div>

            <div className="form-field">
              <div className="form-field__control">
                <input
                  id="formMail"
                  type="email"
                  name="email"
                  className="form-field__input"
                  style={{
                    borderBottom: this.state.validMail
                      ? `1px solid #e8e8e8`
                      : `1px solid #d0021b`,
                  }}
                  onChange={this.handleInputChange}
                  value={this.state.email}
                  required
                  placeholder=" "
                />
                <label
                  htmlFor="formMail"
                  className="form-field__label"
                  style={{
                    color: this.state.validMail ? `#000919` : `#d0021b`,
                  }}
                >
                  <FormattedMessage id="placeholder.email" />
                </label>
                <div className="form-field__bar"></div>
              </div>
            </div>

            <div className="form-field">
              <div className="form-field__control">
                <input
                  id="formPhone"
                  type="tel"
                  name="phone"
                  className="form-field__input"
                  onChange={this.handleInputChange}
                  value={this.state.phone}
                  required
                  placeholder=" "
                />
                <label htmlFor="formPhone" className="form-field__label">
                  <FormattedMessage id="placeholder.phone" />
                </label>
                <div className="form-field__bar"></div>
              </div>
            </div>

            <div className="form-field">
              <div className="form-field__control">
                <input
                  id="formCountry"
                  type="text"
                  name="country"
                  className="form-field__input"
                  onChange={this.handleInputChange}
                  value={this.state.country}
                  required
                  placeholder=" "
                />
                <label htmlFor="formCountry" className="form-field__label">
                  <FormattedMessage id="placeholder.country" />
                </label>
                <div className="form-field__bar"></div>
              </div>
            </div>

            <div
              ref={node => (this.interestDD = node)}
              className={
                "custom-dropdown-long custom-dropdown " +
                (this.state.activeFilterDropdown === "interest-dd"
                  ? "show"
                  : "")
              }
            >
              <div
                onClick={() => this.filterDropdownClick("interest-dd")}
                style={{
                  color: this.state.interest !== "" ? "#1A1B1C" : "#a3a4a4",
                  fontWeight: this.state.interest !== "" ? "600" : "500",
                }}
              >
                <p>
                  <FormattedMessage
                    id={
                      this.state.selectedInterest
                        ? this.state.selectedInterest.label
                        : this.state.empty
                    }
                  />
                </p>

                <label
                  htmlFor="formInterest"
                  className="select-label"
                  style={{
                    transform:
                      this.state.interest !== ""
                        ? "scale(0.73) translateY(-28px) translate(-18%)"
                        : "scale(1) translateY(0)",
                  }}
                >
                  <FormattedMessage id="placeholder.interest_candidate" />
                </label>
                <div
                  className="dd-arrow"
                  style={{
                    transform:
                      this.state.activeFilterDropdown === "interest-dd"
                        ? "rotate(-90deg)"
                        : "rotate(90deg)",
                  }}
                >
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1.32873L1.49482 0L6.74973 5.91177L1.49482 11.8235L0 10.4948L4.07382 5.91177L0 1.32873Z"
                      fill="#1A1B1C"
                    />
                  </svg>
                </div>
              </div>
              <div className="dropdown-content">
                {this.state.interestList.map((obj, index) => {
                  return (
                    <a
                      style={{
                        color: "#1A1B1C",
                        fontWeight:
                          this.state.interest.value === obj.value
                            ? "600"
                            : "400",
                        backgroundColor:
                          this.state.interest.value === obj.value
                            ? "#F4F6F8"
                            : "white",
                      }}
                      key={obj.value}
                      id={obj.value}
                      onClick={() => this.selectDDValue(obj, "interest-dd")}
                    >
                      <FormattedMessage id={obj.label} />
                    </a>
                  )
                })}
              </div>
            </div>

            {this.state.interest !== "" 
              ? (
                <div
              ref={node => (this.typeDD = node)}
              className={
                "custom-dropdown-long custom-dropdown " +
                (this.state.activeFilterDropdown === "type-dd" ? "show" : "")
              }
            >
              <div
                onClick={() => this.filterDropdownClick("type-dd")}
                style={{
                  color: this.state.type !== "" ? "#1A1B1C" : "#a3a4a4",
                  fontWeight: this.state.type !== "" ? "600" : "500",
                }}
              >
                <p>
                  <FormattedMessage
                    id={
                      this.state.selectedType
                        ? this.state.selectedType.label
                        : this.state.empty
                    }
                  />
                </p>

                <label
                  htmlFor="formType"
                  className="select-label"
                  style={{
                    transform:
                      this.state.type !== ""
                        ? "scale(0.73) translateY(-28px) translate(-18%)"
                        : "scale(1) translateY(0)",
                  }}
                >
                  <FormattedMessage id="placeholder.type" />
                </label>
                <div
                  className="dd-arrow"
                  style={{
                    transform:
                      this.state.activeFilterDropdown === "type-dd"
                        ? "rotate(-90deg)"
                        : "rotate(90deg)",
                  }}
                >
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 1.32873L1.49482 0L6.74973 5.91177L1.49482 11.8235L0 10.4948L4.07382 5.91177L0 1.32873Z"
                      fill="#1A1B1C"
                    />
                  </svg>
                </div>
              </div>
              <div className="dropdown-content">
                {specialization.map((obj, index) => {
                  return (
                    <a
                      style={{
                        color: "#1A1B1C",
                        fontWeight:
                          this.state.type.value === obj.value ? "600" : "400",
                        backgroundColor:
                          this.state.type.value === obj.value
                            ? "#F4F6F8"
                            : "white",
                      }}
                      key={obj.value}
                      id={obj.value}
                      onClick={() => this.selectDDValue(obj, "type-dd")}
                    >
                      <FormattedMessage id={obj.label} />
                    </a>
                  )
                })}
              </div>
            </div>
              ) : (
                <></>
              )
            }

            <label style={{marginTop:"12px"}} className="checkbox-wrapper"><FormattedMessage id="placeholder.work_experience" />
              <input type="checkbox" onChange={this.handleExperienceCheckbox} defaultChecked={this.state.work_experience}/>
              <span className="checkmark"></span>
            </label>

            <label className="checkbox-wrapper"><FormattedMessage id="placeholder.speak_german" />
              <input type="checkbox" onChange={this.handleLanguageCheckbox} defaultChecked={this.state.speak_german}/>
              <span className="checkmark"></span>
            </label>

            <div>
              <Button
                type="submit"
                style={{ opacity: this.state.formFilled ? "1" : "0.3" }}
              >
                <FormattedMessage id="contact.cta" />
              </Button>
            </div>
          </Form>
          <TermsCondition>
            <FormattedMessage id="contact.terms_condition_1" /> <Brake />
            <TermsLink onClick={this.handleModalOpen}>
              <FormattedMessage id="contact.terms_condition_2" />
            </TermsLink>
          </TermsCondition>
          {this.state.open ? (
            <Thanks>
              <ThanksMessage>
                <FormattedMessage id="contact.thanks_message" />
              </ThanksMessage>
              <ThanksText>
                <FormattedMessage id="contact.thanks_text" />
              </ThanksText>
            </Thanks>
          ) : (
            <></>
          )}
          <ReactModal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.handleModalClose}
            contentLabel="Terms and conditions modal"
            style={customStyles}
          >
            <ModalHeadingWrapper>
              <ModalHeading>
                <TermsModalTitle>
                  <FormattedMessage id="contact.modal.privacy_policy_title" />
                </TermsModalTitle>
                <CloseModalWrapper>
                  <CloseModal onClick={this.handleModalClose}>
                    <FormattedMessage id="contact.modal.close" />
                  </CloseModal>
                </CloseModalWrapper>
              </ModalHeading>
            </ModalHeadingWrapper>
            <ModalWrapper>
              <TermsModalHeading>
                <FormattedMessage id="contact.modal.privacy_policy_description" />
              </TermsModalHeading>
              <TermsModalHeading>
                <FormattedMessage id="contact.modal.company" />
              </TermsModalHeading>
              <TermsModalText>
                SITINO, s. r. o.
                <br />
                Partizánska 680/1
                <br />
                Poprad, 058 01, <FormattedMessage id="contact.modal.slovakia" />
                <br />
                IČO: 53012194 <br />
                <FormattedMessage id="contact.modal.registered" /> <br />
              </TermsModalText>

              <TermsModalHeading>GDPR</TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.gdpr_description" />
              </TermsModalText>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.personal_data" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.personal_data_description" />
              </TermsModalText>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.processing_range" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.processing_range_description" />
              </TermsModalText>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.scope" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.scope_description" />
              </TermsModalText>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.legal_basis" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.legal_basis_description" />
              </TermsModalText>
              <UnorderedList>
                <ListItem>
                  <FormattedMessage id="contact.modal.legal_basis_para_1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.legal_basis_para_2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.legal_basis_para_3" />
                </ListItem>
              </UnorderedList>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.security_information" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.security_information_description" />
              </TermsModalText>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.recipient" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.recipient_description" />
              </TermsModalText>
              <UnorderedList>
                <ListItem>
                  <FormattedMessage id="contact.modal.recipient_para_1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.recipient_para_2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.recipient_para_3" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.recipient_para_4" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.recipient_para_5" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.recipient_para_6" />
                </ListItem>
              </UnorderedList>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.retention_period" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.retention_period_description" />
              </TermsModalText>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.rights" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.rights_description" />
              </TermsModalText>
              <UnorderedList>
                <ListItem>
                  <FormattedMessage id="contact.modal.rights_para_1" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.rights_para_2" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.rights_para_3" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.rights_para_4" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.rights_para_5" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.rights_para_6" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.rights_para_7" />
                </ListItem>
                <ListItem>
                  <FormattedMessage id="contact.modal.rights_para_8" />
                </ListItem>
              </UnorderedList>

              <TermsModalHeading>
                <FormattedMessage id="contact.modal.exercise_rights" />
              </TermsModalHeading>
              <TermsModalText>
                <FormattedMessage id="contact.modal.exercise_rights_description" />
              </TermsModalText>
            </ModalWrapper>
          </ReactModal>
        </Wrapper>
      </>
    )
  }
}

export function useFormatMessage(messageId) {
  return useIntl().formatMessage({ id: messageId })
}

export default ContactForm
